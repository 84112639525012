import React, { useState, useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

function GalleryPage() {
  const [activeFolder, setActiveFolder] = useState('all');

  // 1) Require images from `src/assets/gallery` (subfolders included)
  const context = require.context(
    './../assets/gallery',
    true,                     // include subdirectories
    /\.(png|jpe?g|svg)$/      // file extensions to match
  );

  // 2) Build a map: folderName -> array of image URLs
  const folderMap = {};
  context.keys().forEach((key) => {
    // key is something like: './bags/img1.jpg'
    const match = key.match(/^\.\/([^/]+)\//);  // extract top-level folder
    if (match) {
      const folderName = match[1];
      if (!folderMap[folderName]) {
        folderMap[folderName] = [];
      }
      const imgUrl = context(key); // actual URL
      folderMap[folderName].push(imgUrl);
    }
  });

  // 3) Folders for our filter buttons
  const folderNames = Object.keys(folderMap);

  // 4) Function to gather images for the chosen folder
  function getImagesForFolder(folder) {
    if (folder === 'all') {
      // Flatten all images from all folders
      return Object.values(folderMap).flat();
    } else {
      return folderMap[folder] || [];
    }
  }

  // 5) Shuffle array helper
  const shuffleArray = (arr) => {
    // If you want a stable shuffle (won't re-shuffle on every re-render),
    // create a new copy first, then sort in place
    return [...arr].sort(() => 0.5 - Math.random());
  };

  // 6) useMemo so that every time activeFolder changes,
  //    we compute a newly shuffled array of images for that folder
  const displayedImages = useMemo(() => {
    const images = getImagesForFolder(activeFolder);
    return shuffleArray(images);
  }, [activeFolder]);

  // 7) Framer Motion variants for quick fade
  const itemVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.2 } },
    exit: { opacity: 0, transition: { duration: 0.2 } },
  };

  return (
    <div>
      
      {/* Folder Buttons (Banner) */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <button           className="filter-button" onClick={() => setActiveFolder('all')}>
          All
        </button>
        {folderNames.map((folder) => (
          <button
          className="filter-button"

            key={folder}
            onClick={() => setActiveFolder(folder)}
          >
            {folder}
          </button>
        ))}
      </div>

      {/* Responsive Image Grid */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
          gap: '10px',
          margin: '20px auto',
          maxWidth: '800px',
        }}
      >
        <AnimatePresence>
          {displayedImages.map((imgUrl, index) => (
            <motion.div
              key={`${activeFolder}-${index}`} // unique key per folder+index
              layout
              variants={itemVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              style={{
                overflow: 'hidden',
                borderRadius: '6px',
              }}
            >
              <img
                src={imgUrl}
                alt={`gallery-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  display: 'block',
                }}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default GalleryPage;
