import React from 'react';

function RecipesPage() {
  // Sample data
  const recipes = [
    {
      id: 1,
      title: 'Sample Recipe 1',
      image: 'https://via.placeholder.com/150',
      instructions: '1. Mix ingredients.\n2. Bake for 30 mins.\n3. Enjoy!'
    },
    {
      id: 2,
      title: 'Sample Recipe 2',
      image: 'https://via.placeholder.com/150',
      instructions: '1. Chop veggies.\n2. Saute in pan.\n3. Serve hot.'
    },
    {
      id: 3,
      title: 'Sample Recipe 3',
      image: 'https://via.placeholder.com/150',
      instructions: '1. Blend fruits.\n2. Add milk.\n3. Chill and serve.'
    }
  ];

  return (
    <div style={{ padding: '120px 20px' }}>
      <h2 style={{ color: '#fff', textAlign: 'center' }}>Recipes Page</h2>
      <div 
        style={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: '20px', 
          justifyContent: 'center' 
        }}
      >
        {recipes.map((recipe) => (
          <div 
            key={recipe.id} 
            style={{
              width: '250px',
              backgroundColor: '#fff',
              borderRadius: '8px',
              padding: '10px',
              textAlign: 'center',
              boxShadow: '0 0 10px rgba(0,0,0,0.3)'
            }}
          >
            <img 
              src={recipe.image} 
              alt={recipe.title} 
              style={{ width: '100%', borderRadius: '6px' }}
            />
            <h3 style={{ margin: '10px 0' }}>{recipe.title}</h3>
            <p style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
              {recipe.instructions}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RecipesPage;
