import React from 'react';
import { useNavigate } from 'react-router-dom';
import './global.css';
import './navbar.css';

function NavBar() {
  const navigate = useNavigate();

  return (
    <div className="navbar">
      <div 
        className="nav-text"
        onClick={() => navigate('/')}
      >
        Rickshaw Club
      </div>
      <div className="nav-links">
        <button className="nav-link" onClick={() => navigate('/join')}>
          Join
        </button>
        <button className="nav-link" onClick={() => navigate('/recipes')}>
          Recipes
        </button>
        <button className="nav-link" onClick={() => navigate('/gallery')}>
          Gallery
        </button>
        <button className="nav-link" onClick={() => navigate('/bespoke')}>
          Bespoke
        </button>
      </div>
    </div>
  );
}

export default NavBar;
