import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import NavBar from './NavBar';
import JoinPage from './pages/JoinPage';
import RecipesPage from './pages/RecipesPage';
import GalleryPage from './pages/GalleryPage';
import BespokePage from './pages/BespokePage';

import './global.css';

function App() {
  const [gradient, setGradient] = useState('');
  const location = useLocation();

  // Helper to generate random color
  const randomColor = () =>
    `#${Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0')}`;

  // Generate random gradient
  const generateGradient = () => {
    const color1 = randomColor();
    const color2 = randomColor();
    const angle = Math.floor(Math.random() * 360);
    return `linear-gradient(${angle}deg, ${color1}, ${color2})`;
  };

  // Initialize the gradient on mount
  useEffect(() => {
    setGradient(generateGradient());
  }, []);

  // Framer Motion variants:
  // - exit: fade out over 0.5s
  // - animate: fade in over 0.2s
  const pageVariants = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: { duration: 0.3 }
    },
    exit: { 
      opacity: 0,
      transition: { duration: 0.05 }
    }
  };

  return (
    <div 
      className="app-container" 
      style={{ 
        minHeight: '100vh',
        background: gradient,
        overflowX: 'hidden',
        position: 'relative'
      }}
    >
      {/* Fixed NavBar */}
      <NavBar />

      {/* AnimatePresence handles transitions for route changes */}
      <AnimatePresence mode="wait">
        <Routes key={location.pathname} location={location}>
          <Route 
            path="/" 
            element={
              <motion.div 
                variants={pageVariants} 
                initial="initial" 
                animate="animate" 
                exit="exit"
                className="home-container"
              >
                {/* You can add any home/landing content here if you like */}
                
                <svg 
                  id="rickshawMain" 
                  width="400px" 
                  height="600px" 
                  viewBox="0 0 200 300"
                  style={{ display: 'block', margin: '0 auto' }}
                >
                  <rect 
                    id="wheelRight" 
                    fill="#4b3832" 
                    x="150.5" 
                    y="180" 
                    width="28.575" 
                    height="45.72" 
                    rx="10.16" 
                    ry="2.54" 
                    stroke="#000" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="5.08" 
                  />
                  <rect 
                    id="wheelLeft" 
                    fill="#4b3832" 
                    x="25.5" 
                    y="180" 
                    width="28.575" 
                    height="45.72" 
                    rx="10.16" 
                    ry="2.54"  
                    stroke="#000" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="5.08" 
                  />
                  <rect 
                    id="bodyMain" 
                    fill="#ffd42a" 
                    x="25.384" 
                    y="41.91" 
                    width="152.4" 
                    height="177.8" 
                    rx="20.32" 
                    ry="88.9"  
                    stroke="#000" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="2.54" 
                  />
                  <path 
                    id="bodySplit" 
                    d="m25.416 128.27h152.37" 
                    stroke="#000" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="2.54" 
                  />
                  <path 
                    id="windshield" 
                    fill="grey" 
                    d="m55.864 49.567c-19.301-0.47584-20.32 40.692-20.32 56.924 5e-5 7.3429 5.235 10.35 10.16 10.35 38.948-0.17765 72.504 0 111.76 0 4.9251 0 10.16-3.0392 10.16-10.35-0.0428-10.912-1.0196-56.412-20.32-56.924-26.223-0.69485-63.788 0.68168-91.44 0z" 
                    stroke="#000" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="2.54" 
                  />
                  <rect 
                    id="indicatorLeft" 
                    fill="orange"
                    x="45.577" 
                    y="138.43" 
                    width="20.574" 
                    height="10.16" 
                    rx="10.16" 
                    ry="2.54"  
                    stroke="#000" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="2.54" 
                  />
                  <rect 
                    id="indicatorRight" 
                    fill="orange" 
                    x="137.02" 
                    y="138.43" 
                    width="20.574" 
                    height="10.16" 
                    rx="10.16" 
                    ry="2.54" 
                    stroke="#000" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="2.54" 
                  />
                  <rect 
                    id="wheelFront" 
                    fill="#4b3832" 
                    x="87.296" 
                    y="196.85" 
                    width="28.575" 
                    height="45.72" 
                    rx="10.16" 
                    ry="2.54"  
                    stroke="#000" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="5.08" 
                  />
                  <path 
                    id="topcover" 
                    fill="black" 
                    d="m45.704 31.75c-3.7199 0.70672-7.9105 2.0343-10.16 5.08-6.1193 8.2853-5.08 30.48-5.08 30.48s7.668-25.508 15.24-25.4c35.597 0.50758 78.106-0.52054 111.76 0 7.5719 0.11712 15.24 25.4 15.24 25.4s1.0393-22.195-5.08-30.48c-2.2495-3.0457-6.4359-4.396-10.16-5.08-36.692-6.7388-75.922-6.8087-111.76 0z" 
                    stroke="black" 
                  />
                  <path 
                    id="bodySplit2" 
                    d="m25.416 128.27h152.37" 
                    stroke="#000" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="2.54" 
                  />
                  <text 
                    id="windshieldBanner" 
                    fill="#000000" 
                    transform="scale(1.0125 .98764)" 
                    x="60" 
                    y="40"  
                    fontFamily="Silom" 
                    fontSize="10px" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="3"
                  > 
                    <tspan 
                      x="60" 
                      y="40" 
                      fill="#000000" 
                      fontFamily="Silom" 
                      fontSize="10px" 
                      strokeWidth="2.5"
                    >
                      RICKSHAW CLUB
                    </tspan>
                  </text>
                  <rect 
                    id="noseplateFront" 
                    fill="grey" 
                    x="93.964" 
                    y="140.97" 
                    width="15.24" 
                    height="35.56" 
                    rx="5.08" 
                    ry="10.16"  
                    stroke="#000" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="2.54" 
                  />
                  <path 
                    id="wheelcoverFront" 
                    fill="black" 
                    d="m76.47 210.83a25.4 25.4 0 0 1 25.327-21.598 25.4 25.4 0 0 1 24.961 22.021l-25.174 3.3781z"  
                    stroke="#000" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="2.54" 
                  />
                  <ellipse 
                    id="headlightFront" 
                    fill="yellow" 
                    cx="101.58" 
                    cy="179.07" 
                    rx="12.919" 
                    ry="11.754"  
                    stroke="#000" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="2.54" 
                  />
                  <rect 
                    id="licensePlate" 
                    fill="#a80" 
                    x="41.894" 
                    y="158.75" 
                    width="27.94" 
                    height="10.16" 
                    rx="2.2013" 
                    ry="2.2013"  
                    stroke="#000" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="2.54" 
                  />
                  <text 
                    id="licenseNumber" 
                    fill="#000000" 
                    transform="scale(.96084 1.0408)" 
                    x="45.122997" 
                    y="161.0748" 
                    display="none"  
                    fontFamily="Silom" 
                    fontSize="9.7621px" 
                    strokeLinecap="round" 
                    strokeLinejoin="bevel" 
                    strokeWidth="2.54" 
                  >
                    <tspan x="0" y="0">7777</tspan>
                  </text>
                </svg>
              </motion.div>
            } 
          />
          
          <Route 
            path="/join" 
            element={
              <motion.div 
                variants={pageVariants} 
                initial="initial" 
                animate="animate" 
                exit="exit"
              >
                <JoinPage />
              </motion.div>
            } 
          />
          
          <Route 
            path="/recipes" 
            element={
              <motion.div 
                variants={pageVariants} 
                initial="initial" 
                animate="animate" 
                exit="exit"
              >
                <RecipesPage />
              </motion.div>
            } 
          />

          <Route 
            path="/gallery" 
            element={
              <motion.div 
                variants={pageVariants} 
                initial="initial" 
                animate="animate" 
                exit="exit"
              >
                <GalleryPage />
              </motion.div>
            } 
          />
          
          <Route 
            path="/bespoke" 
            element={
              <motion.div 
                variants={pageVariants} 
                initial="initial" 
                animate="animate" 
                exit="exit"
              >
                <BespokePage />
              </motion.div>
            } 
          />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
