import React, { useState } from 'react';

function JoinPage() {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Simple alert to show email (You can handle it however you want)
    alert(`Email: ${email}`);
    setEmail('');
  };

  return (
    <div style={{ padding: '120px 20px', textAlign: 'center' }}>
      <h2 style={{ color: '#fff' }}>Join Page</h2>
      <form onSubmit={handleSubmit} style={{ margin: '20px auto', maxWidth: '300px' }}>
        <label style={{ color: '#fff' }}>
          Enter your email:
          <input 
            type="email" 
            required 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              display: 'block',
              width: '100%',
              padding: '10px',
              marginTop: '10px',
              marginBottom: '10px'
            }}
          />
        </label>
        <button 
          type="submit"
          style={{
            backgroundColor: '#000',
            color: '#fddc0c',
            padding: '10px 20px',
            border: 'none',
            cursor: 'pointer',
            fontWeight: 'bold'
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default JoinPage;
